import dayjs from 'dayjs';
import { ReactComponent as Email } from '../../../../shared/assets/email.svg';
import { ReactComponent as Facebook } from '../../../../shared/assets/facebook.svg';
import { ReactComponent as WhatsApp } from '../../../../shared/assets/whatsapp.svg';
import { parseStringsWithWhiteSpaces } from '../../../../shared/helpers/strings';

export const subHeaderSectionsEnum = {
  allOpinions: 'allOpinions',
  withAnswers: 'withAnswers',
  withoutAnswers: 'withoutAnswers',
};
export const limitOpinionList = [
  { id: 1, value: 5, name: 'opinions.positions' },
  { id: 2, value: 10, name: 'opinions.positions' },
  { id: 3, value: 15, name: 'opinions.positions' },
];
export const sortOpinionList = [
  { id: 2, name: 'opinions.newest', value: 'update_time desc' },
  { id: 3, name: 'opinions.bestRating', value: 'rating desc' },
  { id: 4, name: 'opinions.worstRating', value: 'rating' },
];

const ratingConfig = {
  FIVE: 5,
  FOUR: 4,
  THREE: 3,
  TWO: 2,
  ONE: 1,
};
export const parsingStringStars = (rating) => ratingConfig[rating] || 0;

export const OpinionListActionEnum = {
  limit: 'limit',
  orderBy: 'orderBy',
};

export const ANSWER_MAX_LENGTH_FIELD = 1000;
export const opinionUniqueSplitter = '###';

export const parseStringByLanguage = (string) => string && string?.split('(Translated by Google)');
const isTranslated = (string) => string?.includes('(Translated by Google)');

export const parsingOpinionsData = (data) => {
  const parsedBusinessScore = {
    averageRating: data.averageRating,
    totalReviewCount: data.totalReviewCount,
    totalReviewFixedCount: data.averageRating?.toFixed(1).toString().replace('.', ','),
    reviewsWithReply: data.reviews && data.reviews.filter((review) => review.reviewReply).length,
    reviewsWithNoReply: data.reviews && data.reviews.filter((review) => !review.reviewReply).length,
  };
  const parsedBusinessOpinions = data.reviews?.map((el) => {
    const isOpinionTranslated = isTranslated(el.comment);
    const isReplyTranslated = el.reviewReply && isTranslated(el.reviewReply.comment);
    const opinionTextWithNoWhiteSpaces = parseStringsWithWhiteSpaces(el.comment);
    const replyWithNoWhiteSpaces = el.reviewReply?.comment && parseStringsWithWhiteSpaces(el.reviewReply.comment);

    const opinionText = isOpinionTranslated
      ? parseStringByLanguage(opinionTextWithNoWhiteSpaces)
      : opinionTextWithNoWhiteSpaces;
    const opinionReply = isReplyTranslated ? parseStringByLanguage(replyWithNoWhiteSpaces) : replyWithNoWhiteSpaces;
    const [opinionTextNativeLanguage, opinionTextTranslatedLanguage] = opinionText;

    return {
      nativeOpinion: opinionTextNativeLanguage || opinionTextWithNoWhiteSpaces,
      isNew: el.isNew,
      translatedOpinion: parseStringsWithWhiteSpaces(opinionTextTranslatedLanguage),
      nativeReply: opinionReply,
      translatedReply: '',
      createDate: dayjs(el.createTime).format('DD.MM.YYYY'),
      createDateFullTime: dayjs(el.createTime).format('DD.MM.YYYY HH:mm'),
      updateDateFullTime: dayjs(el.updateTime).format('DD.MM.YYYY HH:mm'),
      starRatingAsNumber: parsingStringStars(el.starRating),
      rating: el.starRating,
      id: el.reviewId,
      reviewer: {
        displayName: el.reviewer.displayName,
        profilePhotoUrl: el.reviewer.profilePhotoUrl,
      },
    };
  });
  return { parsedBusinessScore, parsedBusinessOpinions };
};

export const initRequestPayload = {
  limit: limitOpinionList[0].value,
  orderBy: sortOpinionList[0].value,
  pageToken: '',
  businessProfileId: '',
};

export const actionChangeHandlerHelper = (name, id, businessProfileId, order, limit) => {
  if (name === OpinionListActionEnum.limit) {
    const foundLimit = limitOpinionList.find((el) => el.id === id).value;
    return { ...initRequestPayload, orderBy: order, limit: foundLimit, businessProfileId };
  }
  if (name === OpinionListActionEnum.orderBy) {
    const foundOrder = sortOpinionList.find((el) => el.id === id).value;
    return { ...initRequestPayload, orderBy: foundOrder, businessProfileId, limit };
  }
  return true;
};

export const getParsedUniqueTokens = (arr, nextPageToken) => {
  const parsedUniqueValues = [...new Set(arr.map((el) => el.value))];
  const parsedUniqueValuesWith = [...new Set([...parsedUniqueValues, nextPageToken])];
  return parsedUniqueValuesWith.map((el, idx) => ({ id: idx + 1, value: el }));
};
const shareFacebookWithMessage = (link) => {
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`, 'Blank');
};
const shareWhatsappWithMessage = (link) => {
  const whatsappMessage = encodeURIComponent(
    `Cześć! Cenimy Twoją opinię. Czy mógłbyś/mogłabyś zostawić dla nas recenzję na Google? ${link}`,
  );
  const whatsappUrl = `https://api.whatsapp.com/send?text=${whatsappMessage}`;

  window.open(whatsappUrl, 'Blank');
};
const shareViaEmail = (link) => {
  const emailSubject = encodeURIComponent('Prośba o dodanie recenzji na Google');
  const emailBody = encodeURIComponent(
    `Witaj,\n\nBardzo byśmy docenili, gdybyś poświęcił chwilę i zostawił/a recenzję na temat naszej firmy na Google. Oto link do naszych opinii: ${link}\n\nDziękujemy!`,
  );
  const emailAddress = '';

  const mailtoUrl = `mailto:${emailAddress}?subject=${emailSubject}&body=${emailBody}`;
  window.open(mailtoUrl);
};
export const pillsArray = [
  {
    id: 1,
    label: 'Email',
    Icon: Email,
    iconWidth: 18,
    iconHeight: 18,
    strokeColor: 'var(--primary500)',
    onClick: (link) => shareViaEmail(link),
  },
  {
    id: 2,
    label: 'Facebook',
    Icon: Facebook,
    iconWidth: 18,
    iconHeight: 18,
    onClick: (link) => shareFacebookWithMessage(link),
  },
  {
    id: 3,
    label: 'WhatsApp',
    Icon: WhatsApp,
    iconWidth: 18,
    iconHeight: 18,
    onClick: (link) => shareWhatsappWithMessage(link),
  },
];

export const getEmptyStateText = (adminPreview, t) => {
  const emptyStateText = adminPreview ? t('adminControlPanel.noOpinionsYet') : t('opinions.shareLink');
  const emptySateTitle = adminPreview ? t('adminControlPanel.noOpinionsYetTitle') : t('opinions.noOpinionsYet');

  return { emptyStateText, emptySateTitle };
};
