import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './style.module.css';
import { useAuth } from '../../../../shared/helpers/auth';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import Loader from '../../components/Loader';
import Button from '../../../../shared/components/Button';
import HeadingComponent from '../../../../shared/components/Heading';
import SelectBusiness from '../../modules/SetBusinessKeyWords/SelectBusiness';
import SelectBusinessKeyWord from '../../modules/SetBusinessKeyWords/SelectBuisnessKeyword';
import SelectBusinessFilters from '../../modules/SetBusinessKeyWords/SelectBusinessFilters';
import SearchBusiness from '../../modules/SetBusinessKeyWords/SearchBusiness';
import { USERS_WITH_PERMISSION, getBusinessIdFromUrl } from './utils';
import { validateFilters } from '../GenerateReport/utils';
import { ReactComponent as BackgroundImage } from '../../../../shared/assets/backgroundReport.svg';
import { businessTypeEnum } from '../../modules/BusinessProfileCreate/FormShareLocation/utils';
import AddressForm from '../../modules/SetBusinessKeyWords/AddressForm';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { readOnlySubList, subscriptionsConfig } from '../../helpers/constants';
import NoAccessInformation from '../../components/NoAccessInformation';

const StateEnum = {
  loading: 'loading',
  error: 'error',
  success: 'success',
};

const SetBusinessKeyWords = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { api } = useApi();
  const { login, businessProfiles } = useAuth();
  const { showNotification } = useNotification();
  const [filters, setFilters] = useState({});
  const [status, setStatus] = useState(StateEnum.success);
  const [focusedKey, setFocusedKey] = useState(null);
  const [url, setUrl] = useState('');

  const selectedBusinessProfile = useMemo(
    () => businessProfiles.find((el) => el.id === filters.businessProfileId) || { id: null, placeReferenceId: true },
    [businessProfiles, filters.businessProfileId],
  );
  const { subscription } = selectedBusinessProfile;
  const subPlanWithNoReports = readOnlySubList.includes(subscription);

  const readOnlyButton = Object.values(filters).some((el) => el === '' || el === null) || subPlanWithNoReports;
  const foundBusinessProfileType = businessProfiles.find((el) => el.id === filters.businessProfileId)?.businessType;
  const valid = validateFilters(filters);

  const goBackHandler = () => navigate(-1);

  const handleFiltersChange = (name) => (value) => setFilters((prev) => ({ ...prev, [name]: value }));

  const navigateAfterReport = () => {
    navigate({
      pathname: '/report',
      search: url,
    });
  };

  const handleGenerate = async () => {
    try {
      setStatus(StateEnum.loading);
      const payload = {
        netWidth: filters.net,
        area: filters.area * 1000,
        distance: filters.distance * 1000,
        keyword: filters.keyword,
        businessProfileId: filters.businessProfileId,
        storefrontAddress: filters.storefrontAddress,
      };
      const { data: generatedReport } = await api.post('/generate_report/generateReport', payload);
      setFilters((prev) => ({ ...prev, reportId: generatedReport.reportId }));
      setStatus(StateEnum.reportDisplayed);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  const handleLocationChange = (value) => {
    setFilters((prev) => ({ ...prev, storefrontAddress: value }));
  };

  useEffect(() => {
    const params = new URLSearchParams('');
    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'storefrontAddress' && typeof value === 'object' && value !== null) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          params.set(`${subKey}`, subValue);
        });
      } else {
        params.set(key, value);
      }
    });

    navigate(
      {
        pathname: '/set_filters',
        search: valid ? `?${params.toString()}` : '',
      },
      { replace: true },
    );

    setUrl(`?${params.toString()}`);
  }, [filters, valid]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      businessProfileId: getBusinessIdFromUrl(search),
      net: subscription === subscriptionsConfig.trial ? 3 : 7,
      area: 1,
      distance: 1,
      keyword: '',
    }));
  }, []);

  useEffect(() => {
    if (Object.keys(filters).includes('reportId')) {
      navigateAfterReport();
    }
  }, [search]);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <HeadingComponent />
        <div className={classes.form}>
          {USERS_WITH_PERMISSION.includes(login) && (
            <SearchBusiness onChange={handleFiltersChange('businessProfileId')} />
          )}
          <SelectBusiness
            businessProfile={selectedBusinessProfile}
            businessProfiles={businessProfiles}
            handleChange={handleFiltersChange}
          />
          {subPlanWithNoReports ? (
            <NoAccessInformation />
          ) : (
            <>
              {foundBusinessProfileType === businessTypeEnum.CUSTOMER_LOCATION_ONLY && (
                <AddressForm onLocationChange={handleLocationChange} />
              )}
              <SelectBusinessKeyWord
                businessProfile={selectedBusinessProfile}
                onKeywordChange={handleFiltersChange}
                selectedKeyword={filters.keyword}
              />
              <SelectBusinessFilters
                filters={filters}
                focusedKey={focusedKey}
                handleFiltersChange={handleFiltersChange}
                setFocusedKey={setFocusedKey}
                subscription={subscription}
              />
              {status === StateEnum.loading && (
                <div className={classes.overlay}>
                  <Loader className={classes.loader} />
                </div>
              )}
              <div className={classes.buttons}>
                <Button
                  className={`${classes.btnBack} ${classes.button}`}
                  label={t('global.goBack')}
                  onClick={goBackHandler}
                />
                <Button
                  readOnly={readOnlyButton}
                  className={classes.button}
                  label={t('setFilters.generateReport')}
                  onClick={handleGenerate}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <BackgroundImage className={classes.backgroundImage} />
    </div>
  );
};

export default SetBusinessKeyWords;
