export const priceModelsConfig = (t) => [
  {
    title: t('landingPage.pricingSection.free'),
    subTitle: t('landingPage.pricingSection.freeModelSubTitle'),
    price: 0,
    isComing: false,
    features: [
      t('landingPage.pricingSection.createAndImportBusinessCard'),
      t('landingPage.pricingSection.businessCardStatistics'),
      t('landingPage.pricingSection.previewBusinessCard'),
      t('landingPage.pricingSection.previewReviews'),
      t('landingPage.pricingSection.seoCardsManagement'),
      t('landingPage.pricingSection.seoCardsStatisticsMonthly'),
    ],
  },
  {
    title: t('landingPage.pricingSection.mini'),
    subTitle: t('landingPage.pricingSection.miniModelSubTitle'),
    price: 49,
    isComing: true,
    features: [
      t('landingPage.pricingSection.createAndImportBusinessCard'),
      t('landingPage.pricingSection.businessCardStatistics'),
      t('landingPage.pricingSection.previewBusinessCard'),
      t('landingPage.pricingSection.previewReviews'),
      t('landingPage.pricingSection.seoCardsManagement'),
      t('landingPage.pricingSection.seoCardsStatisticsWeekly'),
    ],
  },
  {
    title: t('landingPage.pricingSection.basic'),
    subTitle: t('landingPage.pricingSection.basicModelSubTitle'),
    price: 119,
    isComing: true,
    popular: true,
    features: [
      t('landingPage.pricingSection.miniPlanIncluded'),
      t('landingPage.pricingSection.businessProfileEdit'),
      t('landingPage.pricingSection.aiAssistant'),
      t('landingPage.pricingSection.positioningAssistant'),
      t('landingPage.pricingSection.reviewManagmnet'),
      t('landingPage.pricingSection.managmentAndPlaningPostAndMedia'),
      t('landingPage.pricingSection.cyclicReportBasic'),
      t('landingPage.pricingSection.seoCardsManagement'),
      t('landingPage.pricingSection.seoCardsStatisticDaily'),
    ],
  },
  {
    title: t('landingPage.pricingSection.pro'),
    subTitle: t('landingPage.pricingSection.proModelSubTitle'),
    price: 159,
    isComing: true,
    features: [
      t('landingPage.pricingSection.miniPlanIncluded'),
      t('landingPage.pricingSection.businessProfileEdit'),
      t('landingPage.pricingSection.aiAssistant'),
      t('landingPage.pricingSection.positioningAssistant'),
      t('landingPage.pricingSection.reviewManagmnet'),
      t('landingPage.pricingSection.managmentAndPlaningPostAndMedia'),
      t('landingPage.pricingSection.cyclicReportPro'),
      t('landingPage.pricingSection.seoCardsManagement'),
      t('landingPage.pricingSection.seoCardsStatisticHourly'),
    ],
  },
];
