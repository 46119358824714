import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApiContext, makeApiProvider } from '../../helpers/api';
import { getStorageTokens, useAuth } from '../../helpers/auth';
import WelcomeLayout from '../../../app/SEO/components/Layout';
import ReportLayout from '../../../app/SEO/components/ReportLayout';
import RegisterMethods from '../../../app/SEO/views/RegisterMethods';
import Welcome from '../../../app/SEO/views/Welcome';
import AddPost from '../../../app/SEO/views/AddPost';
import Login from '../../../app/SEO/views/Login';
import GenerateReport from '../../../app/SEO/views/GenerateReport';
import PickBusinessProfiles from '../../../app/SEO/views/PickBusinessProfiles';
import Dashboard from '../../../app/SEO/views/Dashboard';
import ReportList from '../../../app/SEO/views/ReportList';
import BusinessProfileEdit from '../../../app/SEO/views/BusinessProfileEdit';
import SetBusinessKeyWords from '../../../app/SEO/views/SetBusinessKeyWords';
import Opinions from '../../../app/SEO/views/Opinions/index';
import AnswerTemplates from '../../../app/SEO/views/AnswerTemplates';
import RemindPassword from '../../../app/SEO/views/RemindPassword';
import Settings from '../../../app/SEO/views/Settings';
import SetPassword from '../../../app/SEO/views/SetPassword';
import BusinessProfileCreate from '../../../app/SEO/views/BusinessProfileCreate';
import { useNotification } from '../../helpers/notification';
import VerificationView from '../../../app/SEO/views/Verification';
import BusinessProfileChangeHistory from '../../../app/SEO/views/BusinessProfileChangeHistory';
import { handleApiError } from '../../../app/SEO/modules/Settings/ChangePassword/utils';
import Loader from '../../../app/SEO/components/Loader';
import { fetchAdminSession, fetchSeoSession } from './utils';
import AdminLayout from '../../../app/Admin/components/AdminLayout';
import DashboardLayout from '../../../app/SEO/components/DashboardLayout';
import WelcomeAdminLayout from '../../../app/Admin/components/AdminWelcomeLayout';
import AdminLogin from '../../../app/Admin/views/AdminLogin';
import RemindAdminPassword from '../../../app/Admin/views/RemindAdminPassword';
import SetAdminPassword from '../../../app/Admin/views/SetAdminPassword';
import { useAdmin } from '../../../app/Admin/helpers/admin';
import AdminSettings from '../../../app/Admin/views/AdminSettings';
import AdministratorList from '../../../app/Admin/views/AdministratorList';
import AdministratorDetails from '../../../app/Admin/views/AdministratorDetails';
import UserList from '../../../app/Admin/views/UserList';
import UserDetails from '../../../app/Admin/views/UserDetails';
import SeoCards from '../../../app/Admin/views/SeoCards';
import SeoCardDetails from '../../../app/Admin/views/SeoCardDetails';
import LandingPage from '../../../app/LandingPage';
import ClientSeoCardList from '../../../app/SEO/views/ClientSeoCardList';
import ClientSeoCardDetails from '../../../app/SEO/views/ClientSeoCardDetails';
import PrivacyPolicy from '../../../app/LandingPage/modules/PrivacyPolicy';
import TermsOfService from '../../../app/LandingPage/modules/TermsOfService';
import classes from './styles.module.css';
import Reauthorization from '../../../app/SEO/views/Reauthorization';
import GoogleAuthorizationModal from '../GoogleAuthorizationModal';

const Router = () => {
  const { pathname, search } = useLocation();

  const { isAuth, setAuth, clearAuth, isAdmin } = useAuth();
  const { setAdmin, role } = useAdmin();
  const { showNotification } = useNotification();
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
  const [tokens, setTokens] = useState(getStorageTokens);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const isModerator = role === 'moderator';

  const apiProvider = useMemo(() => {
    const onLogout = () => {
      clearAuth();
    };
    const handleTokens = ({ token, refreshToken, adminToken, refreshAdminToken }) => {
      setTokens((prev) => ({ ...prev, token, refreshToken, adminToken, refreshAdminToken }));
    };

    const api = makeApiProvider({
      tokens,
      setTokens: handleTokens,
      onLogout,
      setSession: setAuth,
      setAdmin,
      pathname,
      setShowUnauthorizedModal,
    });
    return { tokens, setTokens, api, onLogout };
  }, [tokens, setTokens, setAuth, clearAuth, pathname]);

  useEffect(() => {
    const controller = new AbortController();
    const { api } = apiProvider;
    const isAdminControlPanelOrigin = pathname.includes('/admin');
    if (isAdminControlPanelOrigin) {
      fetchAdminSession({ api, controller, setAuth, handleApiError, t, showNotification, setAdmin });
    } else {
      fetchSeoSession({ api, controller, setAuth, handleApiError, t, showNotification });
    }

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setTokens(getStorageTokens);
  }, [pathname]);

  const grandAuthorization = async (code) => {
    const { api } = apiProvider;
    setIsLoading(true);
    try {
      await api.post('/auth/reauthorization', { code });
      showNotification({ message: t('Reauthorization successful'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (pathname === '/reauthorization') {
      const queryParams = new URLSearchParams(search);
      const code = queryParams.get('code');

      if (code) {
        grandAuthorization(code);
      }
    }
  }, [pathname]);

  if (isAuth === null || isAdmin === null || isLoading) {
    return (
      <div className={classes.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <ApiContext.Provider value={apiProvider}>
      {showUnauthorizedModal && isAuth && <GoogleAuthorizationModal />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="*" element={isAuth && !isAdmin ? <Navigate to="/dashboard" /> : <Navigate to="/welcome" />} />
        {!isAuth ? (
          <Route path="/*" element={<WelcomeLayout />}>
            <Route path="welcome" element={<Welcome />} />
            <Route path="register_methods" element={<RegisterMethods />} />
            <Route path="login" element={<Login />} />
            <Route path="set_password" element={<SetPassword />} />
            <Route path="reauthorization" element={<Reauthorization />} />
            <Route path="remindPassword" element={<RemindPassword />} />
          </Route>
        ) : (
          <>
            <Route path="/*" element={<ReportLayout />}>
              <Route path="report" element={<GenerateReport />} />
              <Route path="pick_business_profiles" element={<PickBusinessProfiles />} />
              <Route path="set_filters" element={<SetBusinessKeyWords />} />
              <Route path="createBusinessProfile" element={<BusinessProfileCreate />} />
            </Route>
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="" element={<Dashboard />} />
              <Route path="report_list" element={<ReportList />} />
              <Route path="edit_business_profile" element={<BusinessProfileEdit />} />
              <Route path="edit_business_profile/history" element={<BusinessProfileChangeHistory />} />
              <Route path="post" element={<AddPost />} />
              <Route path="opinions" element={<Opinions />} />
              <Route path="opinions/answers" element={<AnswerTemplates />} />
              <Route path="settings" element={<Settings />} />
              <Route path="createBusinessProfile" element={<BusinessProfileCreate />} />
              <Route path="verification" element={<VerificationView />} />
              <Route path="cards" element={<ClientSeoCardList />} />
              <Route path="cards/:id" element={<ClientSeoCardDetails />} />
            </Route>
          </>
        )}
        <Route path="/acp/user/changePasswordByEmail" element={<WelcomeAdminLayout />}>
          <Route path="" element={<SetAdminPassword />} />
        </Route>
        <Route path="/admin/*" element={isAdmin ? <Navigate to="/admin" /> : <Navigate to="/admin/auth" />} />
        {isAdmin ? (
          <Route path="/admin" element={<AdminLayout />}>
            {!isModerator && (
              <>
                <Route path="administrators" element={<AdministratorList />} />
                <Route path="administrators/:id" element={<AdministratorDetails />} />
              </>
            )}
            <Route path="users" element={<UserList />} />
            <Route path="users/:id" element={<UserDetails />} />
            <Route path="cards" element={<SeoCards />} />
            <Route path="cards/:id" element={<SeoCardDetails />} />
            <Route path="subscriptions" element={null} />
            <Route path="content_management" element={null} />
            <Route path="settings" element={<AdminSettings />} />
          </Route>
        ) : (
          <Route path="/admin/auth" element={<WelcomeAdminLayout />}>
            <Route path="" element={<AdminLogin />} />
            <Route path="remindPassword" element={<RemindAdminPassword />} />
          </Route>
        )}
      </Routes>
    </ApiContext.Provider>
  );
};

export default Router;
