import React, { useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as ArrowBack } from '../../assets/arrowAlternative.svg';

const Modal = ({
  children,
  contentStyle,
  modalTitle,
  onCancel,
  showArrowBack,
  onBack,
  rwdBackTitle,
  Icon,
  titleStyle,
  customIconStyle,
  backdropLocked
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);

  const onBackdropClick = (e) => {
    if (!modalRef.current.contains(e.target) && !backdropLocked) {
      onCancel(e);
    }
  };

  return (
    <div onClick={onBackdropClick} className={classes.backdrop}>
      <div ref={modalRef} className={clsx(classes.modal, contentStyle)}>
        <div className={classes.content}>
          {rwdBackTitle && (
            <div className={classes.mobileBackBox} onClick={onBack || onCancel}>
              <ArrowBack className={classes.goBackIconRWD} />
              {t(rwdBackTitle)}
            </div>
          )}
          <div className={clsx(classes.title, titleStyle)}>
            {showArrowBack && <ArrowBack onClick={onBack} className={classes.goBackIcon} />}
            <div className={classes.text}>{modalTitle}</div>
            {Icon && <Icon />}
            {!rwdBackTitle
              || (!onCancel && (
                <div onClick={onCancel} className={clsx(classes.iconWrapper, customIconStyle)}>
                  <Close className={clsx(classes.closeIcon, !rwdBackTitle && classes.showClose)} />
                </div>
              ))}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Modal;
