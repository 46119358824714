import React, { useContext } from 'react';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { tokenEnum } from '../../app/SEO/helpers/constants';
import { businessTypeParser } from '../../app/SEO/views/Login/utils';

const apiRefreshPathList = ['/auth/refreshToken', '/acp/auth/refreshToken'];
const https_codes = {
  UNAUTHORIZED: 401,
};
const { adminToken, refreshAdminToken, refreshToken, token } = tokenEnum;

const defalultApi = {
  api: {
    post: async (endpoint, payload, config) => {},
    get: async (endpoint, config) => {},
  },
  setTokens: () => {
    throw 'Api function is not initialized';
  },
  onLogout: () => {
    throw 'Api function is not initialized';
  },
  onSessionRefresh: () => {
    throw 'Api function is not initialized';
  },
  tokens: 'Api function is not initialized',
};

export const ApiContext = React.createContext(defalultApi);

export const useApi = () => useContext(ApiContext);

const getRefreshToken = (isAdmin) => {
  const refreshTokenKey = isAdmin ? refreshAdminToken : refreshToken;
  return localStorage.getItem(refreshTokenKey);
};

const saveTokensLocalStorage = (isAdmin, data) => {
  const tokenKey = isAdmin ? adminToken : token;
  const refreshTokenKey = isAdmin ? refreshAdminToken : refreshToken;
  localStorage.setItem(refreshTokenKey, data.refreshToken);
  localStorage.setItem(tokenKey, data.token);
};

const refreshTokens = async (isAdmin, apiInstance) => {
  const apiPath = isAdmin ? '/acp/auth/refreshToken' : '/auth/refreshToken';
  const refreshTokenReqData = getRefreshToken(isAdmin);
  const { data } = await apiInstance.post(apiPath, { refreshToken: refreshTokenReqData });
  saveTokensLocalStorage(isAdmin, data);
  return data;
};

export const makeApiProvider = ({ tokens, setTokens, onLogout, setSession, setAdmin, pathname, setShowUnauthorizedModal }) => {
  const isAdmin = pathname.includes('/admin');
  const xAuthToken = isAdmin ? tokens.adminToken : tokens.token;
  const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    maxContentLength: 128,
    headers: { 'x-auth-token': xAuthToken, 'Content-Type': 'application/json' },
  });

  const responseSuccess = async (response) => {
    if (response.headers['x-refresh-session'] && !isAdmin) {
      const data = await refreshTokens(isAdmin, apiInstance, setTokens);
      saveTokensLocalStorage(isAdmin, data);
      const { id, login, businessProfiles, subscriptions } = data.session;
      const profileWithBusinessType = businessTypeParser(businessProfiles, subscriptions);
      setSession({
        isAuth: true,
        id,
        login,
        isAdmin,
        businessProfiles: profileWithBusinessType,
      });
    }

    if (response.data && response.headers['content-type'] === 'application/json; charset=utf-8') {
      response.data = camelizeKeys(response.data);
    }
    return response;
  };

  const responseFail = async (error) => {
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject(error);
    }

    if (!isAdmin && error.response.data.code === 'GOOGLE_TOKEN_UNAUTHORIZED') {
      setShowUnauthorizedModal(true);
    }

    if (apiRefreshPathList.includes(error.response.config.url)) {
      return onLogout();
    }

    if (error.response && error.response.status === https_codes.UNAUTHORIZED) {
      const newToken = await refreshTokens(isAdmin, apiInstance);
      setTokens({
        ...tokens,
        [isAdmin ? tokenEnum.adminToken : tokenEnum.token]: newToken.token,
        [isAdmin ? tokenEnum.refreshAdminToken : tokenEnum.refreshToken]: newToken.refreshToken,
      });
      return apiInstance.request({ ...error.response.config, headers: { 'x-auth-token': newToken.token } });
    }
    return Promise.reject(error);
  };

  const readFileAsArrayBuffer = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const requestSuccess = async (config) => {
    if (config.data instanceof File) {
      const file = await readFileAsArrayBuffer(config.data);
      return {
        ...config,
        data: file,
      };
    }

    if (config.method === 'post' && config.data) {
      return {
        ...config,
        data: decamelizeKeys(config.data),
      };
    }

    return config;
  };

  apiInstance.interceptors.request.use(requestSuccess);
  apiInstance.interceptors.response.use(responseSuccess, responseFail);
  return apiInstance;
};
