import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import Loader from '../../../components/Loader';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const KeywordsModal = ({ onKeyWordClick, onClose, businessProfile }) => {
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [busienssKeywords, setBusinessKeywords] = useState([]);
  const [showExpanded, setShowExpanded] = useState(false);
  const expandHandler = () => setShowExpanded((prev) => !prev);

  const onClickHandler = (val) => {
    onKeyWordClick(val);
    onClose();
  };

  useEffect(() => {
    const fetchBusinessKeywords = async () => {
      setIsLoading(true);
      try {
        const {
          data: { keywords },
        } = await api.post('/performance/getKeywords', { businessProfileId: businessProfile.id });
        setBusinessKeywords(keywords);
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
      setIsLoading(false);
    };

    if (businessProfile.id) {
      fetchBusinessKeywords();
    }
  }, [businessProfile]);

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.keywordsInfoBox, showExpanded && classes.keywordsInfoBoxLong)}>
        <div onClick={expandHandler} className={classes.keywordsInfoBoxTitle}>
          {t('setFilters.keywordsForBusiness')}
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.additionalInfo}>{t('setFilters.keywordsAdditionalInfo')}</div>
        </div>
      </div>

      {Boolean(busienssKeywords.length) && (
        <div className={classes.pickKeywords}>
          {t('setFilters.selectKeyword')}
          <div className={clsx(classes.wrapper)}>
            {busienssKeywords.map((el) => (
              <div onClick={() => onClickHandler(el.searchKeyword)} className={clsx(classes.keyword)} key={el.id}>
                <Checkbox checkboxStyle={classes.checkboxStyle} />
                {el.searchKeyword}
              </div>
            ))}
          </div>
        </div>
      )}
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      {busienssKeywords.length === 0 && !isLoading && (
        <div className={classes.noKeywords}>{t('setFilters.noKeywords')}</div>
      )}
    </div>
  );
};
export default KeywordsModal;
