import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../../../../../shared/helpers/state';
import ActionBox from '../ActionBox';
import { urlValidator } from '../../../../../shared/components/Input/validators';
import { urlTransformer } from '../../../../../shared/components/Input/transformers';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const WebsiteForm = ({
  className,
  form,
  setForm,
  businessProfileId,
  setState,
  onError,
  readOnlyAction,
  isEdit,
  editHandler,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [website, setWebsite] = useState('');

  const handleSave = async () => {
    const parsedWebiste = website.length && website.toLowerCase().endsWith('/') ? website : `${website}`;
    try {
      setState(StateEnum.loading);
      await api.post('/business_profile/updateBusinessProfileWebsite', {
        businessProfileId,
        websiteUri: parsedWebiste,
      });
      showNotification({ label: t('global.success'), message: t('global.saveWithSuccess'), type: 'success' });
    } catch (err) {
      setForm((prev) => ({ ...prev, websiteUri: form.websiteUri }));
      if (err.message === 'WebsiteUri is incorrect') {
        showNotification({
          label: t('global.error'),
          message: t('validators.incorrectWebsiteFormat'),
          payload: err,
          type: 'error',
        });
        return;
      }
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
    setForm((prev) => ({ ...prev, websiteUri: parsedWebiste }));
    editHandler();
  };

  const handleCancel = () => {
    setWebsite(form.websiteUri || '');
    editHandler();
  };

  useEffect(() => {
    if (!isEdit) {
      setWebsite(form.websiteUri);
    }
  }, [isEdit, form.websiteUri]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      <Input
        onError={onError}
        validator={urlValidator}
        transformer={urlTransformer}
        label={t('businessProfileEdit.website')}
        readOnly={!isEdit}
        value={website}
        showErrorAfterBlur
        onChange={(value) => setWebsite(value)}
      />
      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSave} readOnlyAction={readOnlyAction} />}
    </div>
  );
};

export default WebsiteForm;
