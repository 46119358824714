import clsx from 'clsx';
import React, { useId } from 'react';
import classes from './styles.module.css';
import { getPosition } from './utils';
import { ReactComponent as Check } from '../../assets/check.svg';

const Checkbox = ({
  label,
  value,
  labelPosition,
  onClick = () => {},
  className,
  disabled,
  checkboxStyle,
  checkIconStyle,
}) => {
  const id = useId();

  const handleClick = () => onClick(!value);

  return (
    <label className={clsx(classes.wrapper, className)} htmlFor={id} style={{ flexFlow: getPosition(labelPosition) }}>
      {label}
      <input
        data-testid="checkbox"
        id={id}
        className={classes.input}
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={handleClick}
      />
      <div className={clsx(classes.checkbox, !disabled && classes.userClick, checkboxStyle)}>
        <Check className={clsx(classes.icon, checkIconStyle)} />
      </div>
    </label>
  );
};

export default Checkbox;
